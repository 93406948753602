<script setup>
import { useI18n } from 'vue-i18n'

import { useModalStore } from '@/store/modalStore'

const modalStore = useModalStore()

const { t } = useI18n({})

function closeModal() {
  modalStore.closeProductModal()
}

function stopClick(e) {
  e.stopPropagation()
}

function navigateToCart() {
  navigateTo('/cart')
  closeModal()
}
</script>

<template>
  <div
    id="modal-id"
    class="min-w-screen animate-animated animate-fadeIn animate-faster flex-center fixed inset-0 z-50 flex h-screen bg-cover bg-center bg-no-repeat px-2 outline-none focus:outline-none"
    @click="closeModal"
  >
    <div class="absolute inset-0 z-0 bg-black opacity-80" />
    <div
      class="modal relative z-30 m-auto w-full max-w-max rounded-xl bg-white p-5 shadow-lg"
      @click="stopClick"
    >
      <!-- content -->
      <div class="flex-center flex flex-col">
        <!-- body -->
        <div class="flex-auto justify-center p-5 text-center">
          <div class="flex-center flex">
            <div class="absolute top-4 right-4">
              <button @click="closeModal">
                <img src="~/assets/images/icons/close.svg" alt="Close">
              </button>
            </div>
            <div>
              <img
                class="h-40"
                src="~/assets/images/modals/bushes.svg"
                alt=""
              >
            </div>
            <div class="bg-primary-light checkmark-circle-1 flex-center absolute top-12 flex rounded-full">
              <div class="bg-primary checkmark-circle-2 flex-center flex rounded-full">
                <svg
                  aria-hidden="true"
                  height="30px"
                  preserveAspectRatio="xMidYMid meet"
                  role="img"
                  viewBox="0 0 32 32"
                  width="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                ><g
                  class="checkmark"
                  fill="none"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                ><path d="M2 20l10 8L30 4" /></g></svg>
              </div>
            </div>
          </div>

          <h2 class="py-4 text-2xl font-bold ">
            {{ t('added_product_modal.title') }}
          </h2>
          <p class="max-w-lg px-8 text-lg">
            {{ t('added_product_modal.subtitle', { product: modalStore.productAdded.title }) }}
          </p>
        </div>
        <!-- footer -->
        <div class="flex flex-col gap-4 md:flex-row w-full">
          <PrimeButton outlined class="w-full bg-white flex flex-center gap-2" @click="closeModal">
            {{ t('added_product_modal.button_2') }}
          </PrimeButton>

          <PaintButton
            class="w-full"
            :button-text="t('added_product_modal.button_1')"
            @button-clicked="navigateToCart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .title-font {
  font-family: interstate, sans-serif;
  font-style: normal;
 }
.skill-bar {
    width: 57%;
    float: left;
    height: 11px;
    border-radius: 5px;
    position: relative;
    margin: 6px 0 12px 0;
    border: 2px solid #00edc2;
}
.checkmark-circle-1 {
    animation: w80 0.5s ease forwards;
    animation-delay: 0.2s;

}
@keyframes w80 {
    from { width: 0px; height: 0px; }
    to { width: 80px; height: 80px; }
}

.checkmark-circle-2 {
  animation: w56 0.5s ease forwards;
  animation-delay: 0.45s;
  width: 0px;
}
@keyframes w56 {
    from { width: 0px; height: 0px; }
    to { width: 56px; height: 56px; }
}

.checkmark {
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: dash 0.5s linear forwards;
  animation-delay: 0.75s;

}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
