<script setup>
import { useCurrentProductStore } from '@/store/currentProductStore'
import { useModalStore } from '@/store/modalStore'

const currentProductStore = useCurrentProductStore()
const modalStore = useModalStore()
const currentImage = ref(0)
const images = computed(() => currentProductStore.currentProduct['360_images'])
const iframe = ref()
function stopClick(e) {
  e.stopPropagation()
}
onMounted(() => {
  const modal = document.querySelector('.modal')
  modal.classList.add('animate-animated', 'animate-slideInUp', 'animate-faster')
})
function reloadIframe() {
  // eslint-disable-next-line no-self-assign
  iframe.value.src = iframe.value.src
}
function previousImage() {
  if (currentImage.value === 0)
    currentImage.value = images.value.length - 1
  else currentImage.value--
  reloadIframe()
}

function nextImage() {
  if (currentImage.value === (images.value.length - 1))
    currentImage.value = 0
  else currentImage.value++
  reloadIframe()
}

function goToImage(imageNumber) {
  currentImage.value = imageNumber
  reloadIframe()
}

function closeModal() {
  const modal = document.querySelector('.modal')
  const bigNodal = document.querySelector('#modal-id')
  modal.classList.add('animate-animated', 'animate-slideOutDown', 'animate-faster')
  bigNodal.classList.add('animate-fadeOut', 'animate-slideOutDown', 'animate-faster')
  setTimeout(() => {
    modalStore.image3dModalShown = false
  }, 400)
}
</script>

<template>
  <div
    id="modal-id"
    class="min-w-screen animate-animated animate-fadeIn animate-faster flex-center fixed inset-0 z-50 flex h-screen bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
    @click="closeModal"
  >
    <div class="absolute inset-0 z-0 bg-black opacity-80" />
    <div
      class="modal relative z-30 m-auto max-w-max rounded-xl bg-white p-5 shadow-lg"
      @click="stopClick"
    >
      <!-- content -->
      <div class="flex-center flex flex-col">
        <!-- body -->
        <div class="w-40vw h-40vh flex-center relative flex flex-auto flex-row justify-between p-5 text-center">
          <img
            class="rotate-180 transform cursor-pointer"
            src="~/assets/images/icons/arrow-right.svg"
            alt="Arrow"
            @click="previousImage"
          >

          <iframe
            ref="iframe"
            class="h-full w-full"
            :src="images[currentImage]"
          />
          <img
            class="cursor-pointer"
            src="~/assets/images/icons/arrow-right.svg"
            alt="Arrow"
            @click="nextImage"
          >
          <div class="absolute bottom-3 left-1/2 flex -translate-x-1/2 transform flex-row gap-2">
            <div
              v-for="(image, key) in images"
              :key="key"
              class="border-1px h-2 w-2 cursor-pointer rounded-full border-gray-300 transition-all duration-500"
              :class="[key === currentImage ? 'bg-primary' : 'bg-white']"
              @click="goToImage(key)"
            />
          </div>

          <div class="w-30 absolute top-8 right-0 h-20 bg-white">
            <button @click="closeModal">
              <img src="~/assets/images/icons/close.svg" alt="Close">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
