<script setup>
import { useModalStore } from '~/store/modalStore'

const store = useModalStore()
const { locale } = useI18n({})

const productModalShown = computed(() => store.getAddProductModalShown)
const noStockModalShown = computed(() => store.noStockModalShown)
const inspireModalShown = computed(() => store.inspireModalShown)
const snackbarShown = computed(() => store.snackbar.shown)
const image3dModalShown = computed(() => store.image3dModalShown)

const bannerMessage = ref()
const bannerLoading = ref(true)
let bot = null

useHead({
  script: [
    {
      src: 'https://cdn.botframework.com/botframework-webchat/latest/webchat.js',
    },
    {
      src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js',
    },
    {
      src: 'https://giobotstorageprd.blob.core.windows.net/giobot-script/GioBot.js',
    },
  ],
  link: [
    {
      rel: 'stylesheet',
      href: 'https://giobotstorageprd.blob.core.windows.net/giobot-script/GioBot.css?version=2',
    },
  ],
})

watch(locale, () => {
  setTimeout(() => {
    const chatContainer = document.getElementById('bot-container')
    chatContainer.remove()
    bot.initialize()
  }, 500)
})

onMounted(() => {
  bot = new GioBot()
  bot.initialize()
})
</script>

<template>
  <div class="flex h-full min-h-screen flex-col justify-between overflow-hidden">
    <div>
      <div
        v-if="false"
        class="bg-primary flex items-center justify-between px-20 text-center text-lg font-thin text-white transition-all duration-300"
        :class="[bannerLoading || !bannerMessage ? 'max-h-0' : 'max-h-60 py-2']"
      >
        <div />
        <div>
          {{ bannerMessage }}
        </div>
        <div
          class="flex-center z-20 flex h-8 w-8 cursor-pointer"
          @click="closeBanner"
        >
          <svg
            class="h-4 w-4 cursor-pointer"
            style="enable-background:new 0 0 460.775 460.775"
            viewBox="0 0 460.775 460.775"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            @click="closeBanner"
          ><path
            d="M285.08 230.397 456.218 59.27c6.076-6.077 6.076-15.911 0-21.986L423.511 4.565a15.55 15.55 0 0 0-21.985 0l-171.138 171.14L59.25 4.565a15.551 15.551 0 0 0-21.985 0L4.558 37.284c-6.077 6.075-6.077 15.909 0 21.986l171.138 171.128L4.575 401.505c-6.074 6.077-6.074 15.911 0 21.986l32.709 32.719a15.555 15.555 0 0 0 21.986 0l171.117-171.12 171.118 171.12a15.551 15.551 0 0 0 21.985 0l32.709-32.719c6.074-6.075 6.074-15.909 0-21.986L285.08 230.397z"
            fill="#FFF"
            stroke="#FFF"
          /></svg>
        </div>
      </div>

      <div
        v-if="new Date() > new Date(2023, 10, 24) && new Date() < new Date(2023, 10, 25)"
        class="flex-center flex w-full flex-wrap bg-black p-4 text-white"
      >
        <span class="px-3 text-yellow-500">BLACK FRIDAY - SUPER SALE</span>
        <span class="px-3">30% KORTING OP ONS HELE ASSORTIMENT</span>
        <span class="px-3">ENKEL GELDIG OP 24 NOVEMBER 2023</span>
      </div>

      <OldBrowser />

      <div class="relative h-full">
        <div class="navbar-font z-200 relative font-bold">
          <NavBar />
        </div>
        <div>
          <slot />
        </div>
      </div>
    </div>

    <Footer class="pb-8 md:px-20" />

    <BlackFridayModal class="z-500 md:px-20" />

    <AddedProductModal
      v-if="productModalShown"
      class="z-500 md:px-20"
    />

    <NoStockModal
      v-if="noStockModalShown"
      class="z-500 md:px-20"
    />

    <InspireModal
      v-if="inspireModalShown"
      class="z-500 md:px-20"
    />

    <Image3d
      v-if="image3dModalShown"
      class="z-500 md:px-20"
    />

    <Snackbar
      v-if="snackbarShown"
      class="z-500"
    />
  </div>
</template>
