<script setup>
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n({})

const show = ref(false)

onMounted(() => {
  if (window.localStorage.getItem('showBlackFridayModal') === 'false')
    show.value = false
  else if (new Date() > new Date(2023, 10, 24) && new Date() < new Date(2023, 10, 25))
    show.value = true
  else
    show.value = false
})

function closeModal() {
  window.localStorage.setItem('showBlackFridayModal', false)
  show.value = false
  window.location.href = `/products/garden/all`
}

function stopClick(e) {
  e.stopPropagation()
}
</script>

<template>
  <div
    v-if="show"
    id="logo-modal-id"
    class="min-w-screen animate-animated animate-fadeIn animate-faster flex-center fixed inset-0 z-50 flex h-screen bg-cover bg-center bg-no-repeat px-2 outline-none focus:outline-none"
    @click="closeModal"
  >
    <div class="absolute inset-0 z-0 bg-black opacity-80" />
    <div
      class="md:max-w-1/2 modal relative z-30 m-auto rounded-xl bg-white shadow-lg p-4"
      @click="stopClick"
    >
      <div class="flex-center flex flex-col">
        <img
          class="mt-1 w-full rounded-lg"
          :src="`/assets/black-friday/${locale}.jpg`"
          alt=""
        >
        <!-- footer -->
        <div class="my-8 flex flex-row gap-8">
          <PaintButton
            :button-text="t('black_friday.show_products')"
            @button-clicked="closeModal()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
