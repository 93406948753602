<script lang="ts" setup>
import Bowser from 'bowser'

let isValidBrowser: boolean = true
let browser_name: string = ''
let browser_version: string = ''

if (process.client) {
  const browser = Bowser.getParser(window.navigator.userAgent)

  browser_name = browser.getBrowserName()
  browser_version = browser.getBrowserVersion()

  isValidBrowser = browser.satisfies({
    chrome: '>=119',
    firefox: '>=115',
    opera: '>=101',
    safari: '>=16',
    edge: '>=119',
  }) ?? true
}
</script>

<template>
  <div v-if="!isValidBrowser" class="z-1000 bg-red-500">
    <div class="container mx-auto flex gap-4 flex-center text-white p-2">
      <span class="i-mdi-warning" />
      {{ $t('old_browser.text', {
        browser_name,
        browser_version,
      }) }}
    </div>
  </div>
</template>
