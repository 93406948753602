import { defineStore } from 'pinia'
import inspire_1 from '@/assets/images/inspire/inspire_1.webp'
import inspire_2 from '@/assets/images/inspire/inspire_2.webp'
import inspire_3 from '@/assets/images/inspire/inspire_3.webp'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useInspireStore = defineStore('inspire', {
  state: () => ({
    inspireImages: [inspire_1, inspire_2, inspire_3],
  }),
})
