<script setup>
import { useInspireStore } from '@/store/inspireStore'
import { useModalStore } from '@/store/modalStore'

const modalStore = useModalStore()
const inspireStore = useInspireStore()
const images = computed(() => inspireStore.inspireImages)
const currentImage = ref(0)
const shownImage = ref(null)
const isSwitching = ref(false)
onMounted(() => {
  const modal = document.querySelector('.modal')
  modal.classList.add('animate-animated', 'animate-slideInUp', 'animate-faster')
})

function previousImage() {
  if (!isSwitching.value) {
    isSwitching.value = true
    shownImage.value.classList.add('carrousel-animation-previous')
    setTimeout(() => {
      if (currentImage.value === 0)
        currentImage.value = images.value.length - 1
      else currentImage.value--
    }, 500)
    setTimeout(() => {
      shownImage.value.classList.remove('carrousel-animation-previous')
      isSwitching.value = false
    }, 1000)
  }
}

function nextImage() {
  if (!isSwitching.value) {
    isSwitching.value = true
    shownImage.value.classList.add('carrousel-animation-next')
    setTimeout(() => {
      if (currentImage.value === (images.value.length - 1))
        currentImage.value = 0
      else currentImage.value++
    }, 500)
    setTimeout(() => {
      shownImage.value.classList.remove('carrousel-animation-next')
      isSwitching.value = false
    }, 1000)
  }
}

function goToImage(imageNumber) {
  if (!isSwitching.value && currentImage.value !== imageNumber) {
    isSwitching.value = true
    shownImage.value.classList.add('carrousel-animation-next')
    setTimeout(() => {
      currentImage.value = imageNumber
    }, 500)
    setTimeout(() => {
      shownImage.value.classList.remove('carrousel-animation-next')
      isSwitching.value = false
    }, 1000)
  }
}

function closeModal() {
  const modal = document.querySelector('.modal')
  const bigNodal = document.querySelector('#modal-id')
  modal.classList.add('animate-animated', 'animate-slideOutDown', 'animate-faster')
  bigNodal.classList.add('animate-fadeOut', 'animate-slideOutDown', 'animate-faster')
  setTimeout(() => {
    modalStore.inspireModalShown = false
  }, 400)
}

function stopClick(e) {
  e.stopPropagation()
}
</script>

<template>
  <div
    id="modal-id"
    class="min-w-screen animate-animated animate-fadeIn animate-faster flex-center fixed inset-0 z-50 flex h-screen bg-cover bg-center bg-no-repeat px-2 outline-none focus:outline-none"
    @click="closeModal"
  >
    <div class="absolute inset-0 z-0 bg-black opacity-80" />
    <div
      class="modal relative z-30 m-auto w-full max-w-max rounded-xl bg-white px-5 shadow-lg"
      @click="stopClick"
    >
      <!-- content -->
      <div class="flex-center flex flex-col">
        <!-- body -->
        <div class="flex-auto justify-center px-5 text-center">
          <div class="flex-center flex">
            <div class="absolute top-4 right-4">
              <button @click="closeModal">
                <img src="~/assets/images/icons/close.svg" alt="Close">
              </button>
            </div>
          </div>
          <div class="md:px-20">
            <div class="flex-center flex flex-row gap-4 md:gap-12">
              <img
                class="rotate-180 transform cursor-pointer"
                src="~/assets/images/icons/arrow-right.svg"
                alt="Arrow"
                @click="previousImage"
              >
              <img
                ref="shownImage"
                class="h-50vh w-60vw md:w-40vw rounded object-scale-down"
                :src="images[currentImage].url"
                alt=""
              >
              <img
                class="cursor-pointer"
                src="~/assets/images/icons/arrow-right.svg"
                alt=""
                @click="nextImage"
              >
              <div class="absolute bottom-10 left-1/2 flex -translate-x-1/2 transform flex-row gap-2">
                <div
                  v-for="(image, key) in images"
                  :key="key"
                  class="border-1px h-2 w-2 cursor-pointer rounded-full border-gray-300 transition-all duration-500"
                  :class="[key === currentImage ? 'bg-primary' : 'bg-white']"
                  @click="goToImage(key)"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- footer -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .title-font {
  font-family: interstate, sans-serif;
  font-style: normal;
 }
.carrousel-animation-previous {
  animation: moveCarrouselPrevious 1s;
}

@keyframes moveCarrouselPrevious {
  0%   {opacity: 1; transform: translateX(0px);}
  40%   {opacity: 0; transform: translateX(20px);}
  60%   {opacity: 0; transform: translateX(-20px);}
  100%   {opacity: 1; transform: translateX(0px);}
}

.carrousel-animation-next {
  animation: moveCarrouselNext 1s;
}

@keyframes moveCarrouselNext{
  0%   {opacity: 1; transform: translateX(0px);}
  40%   {opacity: 0; transform: translateX(-20px);}
  60%   {opacity: 0; transform: translateX(20px);}
  100%   {opacity: 1; transform: translateX(0px);}
}
</style>
