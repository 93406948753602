<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <ClientOnly>
    <NavigationSearchBar />
    <template #fallback>
      <div class="w-full bg-sand max-w-screen flex flex-row justify-between rounded-xl bg-opacity-40">
        <input
          class="bg-sand h-full w-full rounded-l-xl bg-opacity-0 p-4"
          disabled
          :placeholder="t('navbar.search_placeholder')"
        >
        <img class="rounded-r-xl px-4 " src="~/assets/images/icons/search.svg" alt="Search">
      </div>
    </template>
  </ClientOnly>
</template>
