<script setup>
import { useI18n } from 'vue-i18n'

defineProps({
  link: {
    type: Array,
    required: true,
  },
})

const { hook } = useNuxtApp()

const productStore = useProductStore()

const { t } = useI18n({})

const { public: { environment } } = useRuntimeConfig()
const activateSecondChances = environment !== 'production'

const route = useRoute()

const url = ref(route.path)

const dropdownTrigger = ref(null)

function handleMouseOverButton() {
  dropdownTrigger.value.classList.add('group')
}

onMounted(() => {
  hook('page:finish', async () => {
    dropdownTrigger.value?.classList.remove('group')
  })
})
</script>

<template>
  <div ref="dropdownTrigger" class="group z-[250] inline-block w-full lg:relative cursor-pointer" @mouseover="handleMouseOverButton" @focus="handleMouseOverButton">
    <div class="flex">
      <button class="flex items-center gap-1 font-bold">
        <span :class="{ 'text-primary': link?.includes(url) }">{{ t('navbar.products') }}</span>
        <span class="i-mdi-chevron-down text-lg transform duration-500 group-hover:rotate-180" />
      </button>
    </div>

    <div
      class="absolute left-0 z-[250] hidden w-full bg-transparent px-4 text-lg group-hover:block lg:left-1/2 lg:w-auto lg:min-w-max lg:-translate-x-20 lg:transform"
    >
      <div
        class="shadow-full group-hover:(animate-animated animate-zoomIn animate-faster) dropdown z-50 mt-8 grid min-w-max gap-8 rounded-lg bg-white p-8 transition lg:grid-cols-3"
      >
        <div class="flex flex-col">
          <NuxtLinkLocale to="/products/garden/all">
            <div span="font-bold">
              {{ t(`category.garden`) }}
            </div>
          </NuxtLinkLocale>
          <hr class="my-2">

          <div v-for="gardenLink in productStore.productsByCategory.garden" :key="gardenLink.title">
            <NuxtLinkLocale
              class="hover:(bg-primary-light text-primary ) flex flex-row justify-between gap-8 rounded px-2 py-1 font-normal"
              :to="`/products/garden/${gardenLink.slug}`"
            >
              <div class="max-w-max">
                {{ gardenLink.title }}
              </div>
              <img class="mt-2 h-[10px]" src="~/assets/images/home/chevron-right.svg" alt="Chevron">
            </NuxtLinkLocale>
          </div>
          <NuxtLinkLocale
            v-if="activateSecondChances"
            class="hover:(bg-primary-light) text-primary flex flex-row justify-between gap-8 rounded px-2 py-1 font-normal"
            to="/second-chances/garden/all"
          >
            <div>
              {{ t(`category.second_chance`) }}
            </div>
            <img class="mt-2 h-[10px]" src="~/assets/images/home/chevron-right.svg" alt="Chevron">
          </NuxtLinkLocale>
        </div>

        <div class="flex flex-col">
          <NuxtLinkLocale to="/products/balcony/all">
            <div>
              {{ t(`category.balcony`) }}
            </div>
          </NuxtLinkLocale>
          <hr class="my-2">
          <div v-for="terrasLink in productStore.productsByCategory.balcony" :key="terrasLink.title">
            <NuxtLinkLocale
              class="hover:(bg-primary-light text-primary) flex flex-row justify-between gap-8 rounded px-2 py-1 font-normal"
              :to="`/products/balcony/${terrasLink.slug}`"
            >
              <div class="max-w-max">
                {{ terrasLink.title }}
              </div>
              <img class="mt-2 h-[10px]" src="~/assets/images/home/chevron-right.svg" alt="Chevron">
            </NuxtLinkLocale>
          </div>
          <NuxtLinkLocale
            v-if="activateSecondChances"
            class="hover:(bg-primary-light) text-primary flex flex-row justify-between gap-8 rounded px-2 py-1 font-normal"
            to="/second-chances/balcony/all"
          >
            <div>
              {{ t(`category.second_chance`) }}
            </div>
            <img class="mt-2 h-[10px]" src="~/assets/images/home/chevron-right.svg" alt="Chevron">
          </NuxtLinkLocale>
        </div>

        <div class="flex flex-col gap-20">
          <div class="flex flex-col">
            <NuxtLinkLocale to="/products/deco/all">
              <div>
                {{ t(`category.deco`) }}
              </div>
            </NuxtLinkLocale>
            <hr class="my-2">
            <div v-for="decoLink in productStore.productsByCategory.deco" :key="decoLink.slug">
              <NuxtLinkLocale
                class="hover:(bg-primary-light text-primary) flex flex-row justify-between gap-8 rounded px-2 py-1 font-normal"
                :to="`/products/deco/${decoLink.slug}`"
              >
                <div class="max-w-max">
                  {{ decoLink.title }}
                </div>
                <img class="mt-2 h-[10px]" src="~/assets/images/home/chevron-right.svg" alt="Chevron">
              </NuxtLinkLocale>
            </div>
            <NuxtLinkLocale
              v-if="activateSecondChances"
              class="hover:(bg-primary-light) text-primary flex flex-row justify-between gap-8 rounded px-2 py-1 font-normal"
              to="/second-chances/deco/all"
            >
              <div>
                {{ t(`category.second_chance`) }}
              </div>
              <img class="mt-2 h-[10px]" src="~/assets/images/home/chevron-right.svg" alt="Chevron">
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dropdown::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-100%, 0);
  top: 18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9998;
}
</style>
