<script setup>
import { useModalStore } from '@/store/modalStore'

const modalStore = useModalStore()
const message = computed(() => modalStore.snackbar.message)
const type = computed(() => modalStore.snackbar.type)
const backgroundColor = computed(() => {
  if (modalStore.snackbar.type === 'success')
    return 'bg-green-200'
  if (modalStore.snackbar.type === 'error')
    return 'bg-red-300'
})
let alreadyShown = false
let clickedToClose = false

function closeEarly() {
  const snackbar = document.querySelector('.snackbar')
  clickedToClose = true
  snackbar.classList.add('snackbar-remove')
  setTimeout(() => {
    modalStore.snackbar.shown = false
    alreadyShown = false
  }, 1000)
}
onMounted(() => {
  if (!alreadyShown) {
    alreadyShown = true
    const snackbar = document.querySelector('.snackbar')
    snackbar.classList.add('snackbar-popup')

    setTimeout(() => {
      snackbar.classList.add('snackbar-remove')
      if (!clickedToClose && alreadyShown) {
        setTimeout(() => {
          alreadyShown = false
          modalStore.snackbar.shown = false
        }, 1000)
      }
    }, 4000)
  }
})
</script>

<template>
  <div
    class="z-999 shadow-input-big text-bold snackbar  bottom-40px fixed left-1/2 flex max-w-max -translate-x-1/2 transform flex-row items-center justify-between gap-4 rounded-lg bg-green-200 px-6 py-4 text-white"
    :class="[backgroundColor]"
  >
    <div v-if="type === 'error'">
      <svg
        id="Capa_1"
        class="h-6"
        style="enable-background:new 0 0 192.146 192.146;"
        version="1.1"
        viewBox="0 0 192.146 192.146"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            d="M108.186,144.372c0,7.054-4.729,12.32-12.037,12.32h-0.254c-7.054,0-11.92-5.266-11.92-12.32
          c0-7.298,5.012-12.31,12.174-12.31C103.311,132.062,108.059,137.054,108.186,144.372z M88.44,125.301h15.447l2.951-61.298H85.46
          L88.44,125.301z M190.372,177.034c-2.237,3.664-6.214,5.921-10.493,5.921H12.282c-4.426,0-8.51-2.384-10.698-6.233
          c-2.159-3.849-2.11-8.549,0.147-12.349l84.111-149.22c2.208-3.722,6.204-5.96,10.522-5.96h0.332
          c4.445,0.107,8.441,2.618,10.513,6.546l83.515,149.229C192.717,168.768,192.629,173.331,190.372,177.034z M179.879,170.634
          L96.354,21.454L12.292,170.634H179.879z"
            style="fill:white;"
          />
        </g>
      </svg>
    </div>
    <div>
      {{ message }}
    </div>
    <div>
      <img
        class="h-4 w-4 cursor-pointer"
        src="~/assets/images/icons/close-white.svg"
        alt="Close"
        @click="closeEarly"
      >
    </div>
  </div>
</template>

<style scoped>
.snackbar-popup {
  animation: snackbarPopup 1s;
}

.snackbar-remove {
  animation: snackbarRemove 1s;
}

@keyframes snackbarPopup {
  from   {opacity: 0; transform: translateY(30px) translateX(-50%) scale(0.7);}
  to   {opacity: 1; transform: translateY(0px) translateX(-50%) scale(1);}
}

@keyframes snackbarRemove {
  from   {opacity: 1; transform: translateY(0px) translateX(-50%) scale(1); }
  to  {opacity: 0; transform: translateY(30px) translateX(-50%) scale(1);}
}
</style>
