<script setup>
import { useModalStore } from '~/store/modalStore'

const modalStore = useModalStore()
const loading = ref(false)
const { t, locale } = useI18n({})
const email = ref('')

const authStore = useAuthStore()
if (authStore.me.email)
  email.value = authStore.me.email

function closeModal() {
  modalStore.noStockModalShown = false
}

function stopClick(e) {
  e.stopPropagation()
}

async function postForm() {
  loading.value = true

  const response = await useApi('/api/forms/stock', {
    method: 'POST',
    body: {
      variant_id: modalStore.noStockProduct.id,
      email: email.value,
      language: locale,
    },
  })

  if (response.status.value === 'success') {
    useToastification().success(t('product.no_stock_success'))
    closeModal()
  }
  else { useToastification().error(t('product.no_stock_error')) }
  loading.value = false
}
</script>

<template>
  <div
    id="modal-id"
    class="min-w-screen animate-animated animate-fadeIn animate-faster flex-center fixed  inset-0 z-50 flex h-screen bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
    @click="closeModal"
  >
    <div class="absolute inset-0 z-0 bg-black opacity-80" />
    <div
      class="modal  relative z-30 m-auto max-w-max rounded-xl bg-white  p-5 shadow-lg"
      @click="stopClick"
    >
      <!-- content -->
      <div class="flex-center flex flex-col">
        <!-- body -->
        <div class="flex-center flex flex-auto flex-col p-5 text-center">
          <h2 class="py-4 text-2xl font-bold ">
            {{ t('no_stock_modal.title') }}
          </h2>
          <p class="max-w-lg px-8 text-lg">
            {{ t('no_stock_modal.text') }}
          </p>
          <div class="min-w-1/2 mt-8 flex flex-col gap-2 text-left">
            <div>
              {{ t('no_stock_modal.email') }}
            </div>
            <input
              v-model="email"
              class="shadow-input rounded-xl border-none bg-white"
              :placeholder="t('no_stock_modal.email')"
              type="email"
            >
          </div>
        </div>
        <!-- footer -->
        <div class="my-8 flex flex-row gap-8">
          <PaintButton
            :button-text="t('no_stock_modal.button')"
            :disabled="!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)"
            :loading="loading"
            @button-clicked="postForm()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
